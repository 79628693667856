























import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class VersionInfoTable extends Vue {
  @Prop({ type: String, required: true })
  readonly title: string

  @Prop({ type: String, required: true })
  readonly version: string

  @Prop({ type: String, required: true })
  readonly builtAt: string

  get builtAtFormatted() {
    return moment(this.builtAt).format('D. M. YYYY, HH:mm')
  }
}
