















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component
export default class Pagination extends Vue {
  @Prop({ type: Number, required: true })
  readonly lastPage: number

  @Prop({ type: Number, required: true })
  readonly currentPage: number

  readonly pageSelectionCount = 3

  get pageRange() {
    const start = Math.max(this.currentPage - this.pageSelectionCount, 0)
    const end = Math.min(this.currentPage + this.pageSelectionCount, this.lastPage)
    return _.range(start, end + 1)
  }

  selectPage(page) {
    if (page !== this.currentPage) {
      this.$emit('pageSelected', page)
    }
  }
}
