







import FdpFooter from './components/FdpFooter/index.vue'
import FdpHeader from './components/FdpHeader/index.vue'

export default {
  components: {
    FdpFooter,
    FdpHeader,
  },
}
