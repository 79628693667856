























































import _ from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PrismEditor from 'vue-prism-editor'
import api from '@/api'
import Status from '@/utils/Status'
import Breadcrumbs from '@/components/Breadcrumbs/index.vue'
import Page from '@/components/Page/index.vue'
import SchemasImporter from '@/components/SchemasImporter/index.vue'
import StatusFlash from '@/components/StatusFlash/index.vue'

@Component({
  components: {
    Breadcrumbs, Page, PrismEditor, SchemasImporter, StatusFlash,
  },
})
export default class SchemasImport extends Vue {
  loadingStatus: Status = new Status()

  fdpUrl: string = null

  metadataSchemas: any = null

  breadcrumbs = [{
    label: 'Metadata Schemas',
    to: '/schemas',
  }]

  get fdpUrlEmpty() {
    return _.isEmpty(this.fdpUrl)
  }

  async loadShapes() {
    if (!this.fdpUrlEmpty) {
      try {
        this.loadingStatus.setPending()
        const response = await api.metadataSchemas.getImport(this.fdpUrl)
        this.metadataSchemas = response.data
        this.loadingStatus.setDone()
      } catch (err) {
        this.loadingStatus.setError(`Unable to retrieve metadata schemas from ${this.fdpUrl}`)
      }
    }
  }
}
