















































































































































































import _ from 'lodash'
import { email, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import api from '../../api'
import Breadcrumbs from '../../components/Breadcrumbs/index.vue'
import Page from '../../components/Page/index.vue'
import Status from '../../utils/Status'
import StatusFlash from '../../components/StatusFlash/index.vue'

export default {
  name: 'UserDetail',
  components: { Breadcrumbs, StatusFlash, Page },

  validations() {
    return {
      user: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
      },
      passwordForm: {
        password: { required },
        passwordCheck: {
          passwordMatch(value) {
            return this.passwordForm.password === value
          },
        },
      },
    }
  },

  data() {
    return {
      title: null,
      user: null,
      isCurrentUser: true,
      passwordForm: {
        password: null,
        passwordCheck: null,
      },
      status: new Status(),
      profileSubmitStatus: new Status(),
      passwordSubmitStatus: new Status(),
      breadcrumbs: [{
        label: 'Users',
        to: '/users',
      }],
    }
  },

  computed: {
    ...mapGetters('auth', {
      currentUser: 'user',
    }),
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData()
  },

  methods: {
    getUserUuid() {
      return _.get(this.$route.params, 'id', 'current')
    },

    getUserData() {
      const data = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
      }

      if (this.getUserUuid() !== 'current') {
        _.set(data, 'role', this.user.role)
      }

      return data
    },

    async fetchData() {
      try {
        this.status.setPending()

        const response = await api.users.getUser(this.getUserUuid())
        this.isCurrentUser = this.getUserUuid() === 'current'
        this.user = response.data
        this.setTitle()
        this.status.setDone()
      } catch (error) {
        this.status.setError('Unable to get user profile.')
      }
    },

    async submitProfile() {
      this.$v.user.$touch()

      if (!this.$v.user.$invalid) {
        try {
          this.profileSubmitStatus.setPending()
          await api.users.putUser(this.getUserUuid(), this.getUserData())
          this.setTitle()
          this.profileSubmitStatus.setDone('User profile was successfully updated!')

          if (this.user.uuid === this.currentUser.uuid) {
            await this.$store.dispatch('auth/updateUser', { user: this.user })
          }
        } catch (error) {
          this.profileSubmitStatus.setError('User profile could not be updated.')
        }
      }
    },

    async submitPassword() {
      this.$v.passwordForm.$touch()

      if (!this.$v.passwordForm.$invalid) {
        try {
          this.passwordSubmitStatus.setPending()
          await api.users.putUserPassword(this.getUserUuid(), this.passwordForm.password)
          this.passwordSubmitStatus.setDone('Password was successfully updated!')
        } catch (error) {
          this.passwordSubmitStatus.setError('Password could not be updated.')
        }
      }
    },

    setTitle() {
      this.title = `${this.user.firstName} ${this.user.lastName}`
    },
  },
}
