



















































































































































import { email, required } from 'vuelidate/lib/validators'
import api from '../../api'
import Breadcrumbs from '../../components/Breadcrumbs/index.vue'
import Page from '../../components/Page/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'
import Status from '../../utils/Status'

export default {
  name: 'UserDetail',
  components: { Breadcrumbs, StatusFlash, Page },

  validations() {
    return {
      user: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        password: { required },
        passwordCheck: {
          passwordMatch(value) {
            return this.user.password === value
          },
        },
      },
    }
  },

  data() {
    return {
      user: {
        firstName: null,
        lastName: null,
        email: null,
        role: 'USER',
        password: null,
        passwordCheck: null,
      },
      status: new Status(),
      profileSubmitStatus: new Status(),
      passwordSubmitStatus: new Status(),
      breadcrumbs: [{
        label: 'Users',
        to: '/users',
      }],
    }
  },

  methods: {
    async submit() {
      this.$v.user.$touch()

      if (!this.$v.user.$invalid) {
        this.profileSubmitStatus.setPending()
        try {
          await api.users.postUser(this.user)
          await this.$router.replace('/users')
        } catch (error) {
          this.profileSubmitStatus.setErrorFromResponse(error, 'User profile could not be created.')
        }
      }
    },
  },
}
