



import Page from '../../components/Page/index.vue'

export default {
  name: 'NotAllowed',
  components: { Page },
}
