




































































import _ from 'lodash'
import api from '../../api'
import Page from '../../components/Page/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'
import UserItem from '../../components/UserItem/index.vue'
import Status from '../../utils/Status'

export default {
  name: 'Users',
  components: {
    UserItem,
    StatusFlash,
    Page,
  },

  data() {
    return {
      users: null,
      status: new Status(),
    }
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      try {
        this.status.setPending()

        const response = await api.users.getUsers()
        this.users = _.orderBy(response.data, ['firstName', 'lastName'], ['asc'])
        this.status.setDone()
      } catch (error) {
        this.status.setError('Unable to get users.')
      }
    },

    async deleteUser(user) {
      if (window.confirm(`Are you sure you want to delete ${user.firstName} ${user.lastName}?`)) {
        try {
          await api.users.deleteUser(user)
          this.fetchData()
        } catch (error) {
          this.status.setError('Unable to delete users.')
        }
      }
    },
  },
}
