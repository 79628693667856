



















































































































import _ from 'lodash'
import axios from 'axios'
import { Component } from 'vue-property-decorator'
import EntityBase from '@/components/EntityBase'
import api from '../../api'
import Breadcrumbs from '../Breadcrumbs/index.vue'
import Page from '../Page/index.vue'
import StatusFlash from '../StatusFlash/index.vue'
import UserItem from '../UserItem/index.vue'
import Status from '../../utils/Status'

@Component({
  components: {
    Breadcrumbs,
    Page,
    StatusFlash,
    UserItem,
  },
})
export default class EntitySettings extends EntityBase {
  inviteForm: any = {
    userUuid: null,
    membershipUuid: null,
  }

  inviteStatus: Status = new Status()

  members: any = null

  memberships: any = null

  users: any = null

  async fetchData(): Promise<void> {
    try {
      this.status.setPending()
      const [entity, meta, members, users, memberships] = await this.loadData()

      this.buildGraph(entity.data)
      this.members = _.orderBy(members.data, ['user.firstName', 'user.lastName'], ['asc'])
      this.users = this.createUsers(users.data, this.members)
      this.memberships = this.createMemberships(memberships.data)
      this.inviteForm.membershipUuid = _.get(this.memberships, '0.uuid')
      this.breadcrumbs = this.config.createBreadcrumbsWithSelf(meta.data.path, this.subject)
      this.status.setDone()
    } catch (error) {
      if (_.get(error, 'response.status') === 403) {
        await this.$router.replace(this.config.toUrl(this.entityId))
      } else {
        this.status.setErrorFromResponse(error, 'Unable to get data.')
      }
    }
  }

  async loadData() {
    return axios.all([
      this.config.api.get(this.entityId),
      this.config.api.getMeta(this.entityId),
      this.config.api.getMembers(this.entityId),
      api.users.getUsers(),
      api.memberships.getMemberships(),
    ])
  }

  createUsers(users: Array<any>, members: Array<any>): Array<any> {
    return _.orderBy(users
      .filter((u) => members.filter((m) => m.user.uuid === u.uuid).length === 0)
      .map((u) => ({
        ...u,
        fullName: `${u.firstName} ${u.lastName}`,
      })), ['firstName', 'lastName'], ['asc'])
  }

  createMemberships(memberships: Array<any>): Array<any> {
    return memberships.filter((m) => _.includes(m.allowedEntities, this.config.uuid))
  }

  async submitInvite(): Promise<void> {
    if (this.inviteForm.userUuid !== null && this.inviteForm.membershipUuid !== null) {
      try {
        this.inviteStatus.setPending()
        await this.config.api.putMember(
          this.$route.params.id,
          this.inviteForm.userUuid,
          this.inviteForm.membershipUuid,
        )

        this.inviteStatus.setStatus(Status.DEFAULT)
        this.inviteForm = {
          userUuid: null,
          membershipUuid: null,
        }
        this.graph = null
        this.fetchData()
      } catch (error) {
        this.inviteStatus.setErrorFromResponse(error, 'User could not be invited.')
      }
    }
  }

  async updateMember(userUuid: string, membershipUuid: string): Promise<void> {
    try {
      await this.config.api.putMember(this.entityId, userUuid, membershipUuid)
      this.fetchData()
    } catch (error) {
      this.status.setErrorFromResponse(error, 'Unable to update user membership.')
    }
  }

  async removeMember(user: any): Promise<void> {
    if (window.confirm(`Are you sure you want to remove ${user.firstName} ${user.lastName}?`)) {
      try {
        await this.config.api.deleteMember(this.entityId, user.uuid)
        this.fetchData()
      } catch (error) {
        this.status.setErrorFromResponse(error, 'Unable to remove user.')
      }
    }
  }
}
