















































import { mapGetters } from 'vuex'
import StatusFlash from '../../components/StatusFlash/index.vue'
import Status from '../../utils/Status'

export default {
  name: 'Login',
  components: { StatusFlash },
  data() {
    return {
      email: '',
      password: '',
      status: new Status(),
    }
  },

  computed: {
    ...mapGetters('auth', {
      authenticated: 'authenticated',
    }),
  },

  created() {
    if (this.authenticated) {
      this.$router.replace('/')
    }
  },

  methods: {
    submit() {
      if (!this.email || !this.password) return

      this.status.setPending()
      this.$store.dispatch('auth/authenticate', {
        email: this.email,
        password: this.password,
        onSuccess: () => this.$router.push('/'),
        onError: () => this.status.setError('Login failed'),
      })
    },
  },
}
