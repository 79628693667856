






















import { Component, Prop, Vue } from 'vue-property-decorator'
import UserAvatar from '../UserAvatar/index.vue'

@Component({
  components: { UserAvatar },
})
export default class UserItem extends Vue {
  @Prop({ required: true })
  readonly user: any

  @Prop({ type: Boolean, default: false })
  readonly frameless: boolean
}
