




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Breadcrumbs extends Vue {
  @Prop({ type: Array, required: true })
  readonly links: Array<any>

  @Prop({ type: String, required: true })
  readonly current: String
}
