














import _ from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class SearchField extends Vue {
  q: string = ''

  routeQ: string = null

  submit() {
    if (this.q.length > 0) {
      this.$router.push({ path: '/search', query: { q: this.q } }).catch(() => {})
    }
  }

  created(): void {
    this.setQ()
  }

  @Watch('$route')
  setQ() {
    this.routeQ = _.get(this.$route, 'query.q', '')
    this.q = this.routeQ
  }
}
