











































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Item extends Vue {
  @Prop({ required: true })
  readonly item: any
}
