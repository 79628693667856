






































































































import _ from 'lodash'
import {
  Component, Prop, Vue,
} from 'vue-property-decorator'
import PrismEditor from 'vue-prism-editor'
import semverRcompare from 'semver/functions/rcompare'
import Page from '@/components/Page/index.vue'
import Status from '@/utils/Status'
import api from '@/api'
import StatusFlash from '@/components/StatusFlash/index.vue'
import Breadcrumbs from '@/components/Breadcrumbs/index.vue'

@Component({
  components: {
    Breadcrumbs, Page, PrismEditor, StatusFlash,
  },
})
export default class SchemasImporter extends Vue {
  @Prop({ type: Array, default: [] })
  readonly metadataSchemas: any

  importStatus: Status = new Status()

  metadataSchemaGroups: any = []

  showDefinition = []

  get selectedSchemas() {
    if (this.metadataSchemaGroups) {
      const checkStatus = (schema) => _.get(schema, 'status') !== 'ALREADY_IMPORTED'
      const checkSelected = (schema) => _.get(schema, 'selected')
      return _.flatten(this.metadataSchemaGroups)
        .filter((schema) => checkStatus(schema) && checkSelected(schema))
        .map((schema) => _.get(schema, 'schema'))
    }
    return []
  }

  get someShapeSelected() {
    return this.selectedSchemas.length > 0
  }

  mounted() {
    this.createMetadataGroups()
  }

  isDefinitionVisible(uuid) {
    return this.showDefinition.some((u) => u === uuid)
  }

  showShape(uuid) {
    this.showDefinition.push(uuid)
  }

  hideShape(uuid) {
    this.showDefinition = this.showDefinition.filter((u) => u !== uuid)
  }

  createMetadataGroups() {
    const preselected = this.metadataSchemas.map((schema) => {
      if (schema.status === 'ALREADY_IMPORTED') {
        return { ...schema, selected: true }
      }
      return schema
    })
    const groups = Object.values(_.groupBy(preselected, (ms) => ms.schema.uuid))
    const comparator = (a, b) => semverRcompare(a.schema.version, b.schema.version)
    const mapName = (a) => _.get(a, '0.schema.name')
    this.metadataSchemaGroups = _.sortBy(groups.map((group) => group.sort(comparator)), mapName)
  }

  async importShapes() {
    if (this.someShapeSelected) {
      try {
        this.importStatus.setPending()
        await api.metadataSchemas.postImport(this.selectedSchemas)
        this.importStatus.setDone('Metadata schemas were successfully imported')
      } catch (err) {
        this.importStatus.setErrorFromResponse(err, 'Unable to import metadata schemas')
      }
    }
  }
}
