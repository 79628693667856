










import _ from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LineNumbers extends Vue {
  @Prop({ required: true })
    from: number

  @Prop({ required: true })
    to: number

  get numbers() {
    return _.range(this.from, this.to)
  }
}
