













































































































































































































































































import { required } from 'vuelidate/lib/validators'
import PrismEditor from 'vue-prism-editor'
import _ from 'lodash'
import { SHACLFormParser } from '@/components/ShaclForm/Parser/SHACLFormParser'
import FormRenderer from '@/components/ShaclForm/FormRenderer.vue'
import api from '../../api'
import Status from '../../utils/Status'
import Breadcrumbs from '../../components/Breadcrumbs/index.vue'
import Page from '../../components/Page/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'

export default {
  name: 'SchemaDetail',
  components: {
    FormRenderer,
    Breadcrumbs,
    Page,
    PrismEditor,
    StatusFlash,
  },

  data() {
    return {
      errors: [],
      schema: {
        name: null,
        description: null,
        abstractSchema: false,
        definition: null,
        extendsSchemaUuids: [],
        suggestedResourceName: null,
        suggestedUrlPrefix: null,
      },
      status: new Status(),
      submitStatus: new Status(),
      schemas: [],
      schemaOptions: [],
      breadcrumbs: [{
        label: 'Metadata Schemas',
        to: '/schemas',
      }],
      currentSchema: null,
      viewPreview: false,
      form: null,
      previewError: null,
      data: { subject: null, data: {} },
    }
  },

  computed: {
    isEdit() {
      return !!this.$route.params.uuid
    },

    title() {
      return this.currentSchema
        ? `Edit ${this.currentSchema.name}`
        : 'Create Metadata Schema'
    },
  },

  validations() {
    return {
      schema: {
        name: { required },
        description: {},
        abstractSchema: {},
        definition: { required },
        extendsSchemaUuids: {
          $each: {
            uuid: { required },
          },
        },
        suggestedResourceName: {},
        suggestedUrlPrefix: {},
      },
    }
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData()
  },

  methods: {
    getSchemaVersions(schemaUuid) {
      return this.schemas.reduce((acc, schema) => {
        if (schema.uuid === schemaUuid) {
          return schema.versions
        }
        return acc
      }, [])
    },

    async fetchData() {
      try {
        this.status.setPending()
        const schemas = await api.metadataSchemas.getForExtend()
        this.schemas = schemas.data
        this.schemaOptions = _.orderBy(schemas.data, ['name'], ['asc'])
          .map((schema) => ({ key: schema.uuid, value: schema.name }))
        this.schemaOptions.unshift({ key: null, value: '- select -' })

        if (this.isEdit) {
          const schema = await api.metadataSchemas.getDraft(this.$route.params.uuid)
          this.schema = this.requestDataToFormData(schema.data)
          this.currentSchema = schema.data
        }

        this.status.setDone()
      } catch (error) {
        this.status.setError('Unable to get schema')
      }
    },

    async release() {
      await this.submit(true)
    },

    async save() {
      await this.submit(false)
    },

    async submit(release) {
      this.$v.schema.$touch()

      if (!this.$v.schema.$invalid) {
        this.submitStatus.setPending()
        this.errors = []
        try {
          const request = this.isEdit
            ? api.metadataSchemas.putDraft
            : api.metadataSchemas.post
          const schema = await request(this.formDataToRequestData(this.schema))
          const nextRoute = release ? `/schemas/${schema.data.uuid}/release` : '/schemas'
          await this.$router.push(nextRoute)
        } catch (error) {
          const errors = _.get(error, 'response.data.errors')

          if (errors) {
            this.submitStatus.setError('Unable to save schema')
            this.errors = errors
          } else {
            this.submitStatus.setErrorFromResponse(error, 'Schema could not be saved.')
          }
        } finally {
          window.scrollTo(0, 0)
        }
      }
    },

    formDataToRequestData(formData) {
      const data = { ...formData }
      data.extendsSchemaUuids = formData.extendsSchemaUuids.map(({ uuid }) => uuid)
      data.description = formData.description || ''
      return data
    },

    requestDataToFormData(requestData) {
      const formData = { ...requestData }
      formData.extendsSchemaUuids = requestData.extendsSchemaUuids.map((uuid) => ({ uuid }))
      return formData
    },

    addExtends() {
      this.schema.extendsSchemaUuids.push({ uuid: null, version: null })
    },

    removeExtends(index) {
      this.schema.extendsSchemaUuids.splice(index, 1)
    },

    setViewPreview(viewPreview) {
      if (viewPreview) {
        try {
          this.previewError = null
          this.data = { subject: null, data: {} }

          const parser = new SHACLFormParser(this.schema.definition)
          this.form = {
            targetClasses: [],
            groups: parser.parseAllAndGroup(),
          }
        } catch (error) {
          this.previewError = error
        }
      }

      this.viewPreview = viewPreview
    },
  },
}
