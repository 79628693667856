





















import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from '../Avatar/index.vue'

@Component({
  components: { Avatar },
})
export default class ItemSimple extends Vue {
  @Prop({ required: true })
  readonly avatarInitials: any

  @Prop({ required: true })
  readonly avatarValue: any

  @Prop({ type: Boolean, default: false })
  readonly frameless: boolean
}
