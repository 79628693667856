









import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import api from '../../api'

@Component
export default class RdfLink extends Vue {
  @Prop({ type: String })
  readonly uri: string

  @Prop({ type: String })
  readonly label: string

  @Prop({ type: Boolean, default: false })
  readonly labelResolved: boolean

  resolvedLabel : string = null

  async created(): Promise<void> {
    if (!this.labelResolved) {
      try {
        const label = await api.label.getLabel(this.uri)
        this.resolvedLabel = label.data.label
      } catch {
        // nothing could be fetched, keep default label
      }
    }
  }
}
