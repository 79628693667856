







































import _ from 'lodash'
import ItemTree from '@/views/MyMetadata/ItemTree.vue'
import api from '../../api'
import Page from '../../components/Page/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'
import Status from '../../utils/Status'

export default {
  name: 'Dashboard',
  components: {
    ItemTree,
    Page,
    StatusFlash,
  },
  data() {
    return {
      dashboard: null,
      status: new Status(),
    }
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      try {
        this.status.setPending()

        const response = await api.repository.getDashboard()
        this.dashboard = response.data
        this.status.setDone()
      } catch (error) {
        this.status.setErrorFromResponse(error, 'Unable to load dashboard.')
      }
    },

    toggleOpen(entity) {
      this.changeOpen((current) => (
        current.uri === entity.uri ? !current.open : current.open
      ))
    },

    expandAll() {
      this.changeOpen(() => true)
    },

    collapseAll() {
      this.changeOpen(() => false)
    },

    changeOpen(f) {
      this.dashboard = this.dashboard.map((c) => ({
        ...c,
        open: f(c),
        children: c.children.map((d) => ({
          ...d,
          open: f(d),
        })),
      }))
    },

    sortByTitle(list) {
      return _.orderBy(list, ['title'], ['asc'])
    },
  },
}
