














































































































































































































































































































































































































































































































import { required, url } from 'vuelidate/lib/validators'
import PrismEditor from 'vue-prism-editor'
import api from '@/api'
import Status from '@/utils/Status'
import Page from '../../components/Page/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'

export default {
  name: 'FdpSettings',
  components: {
    Page,
    PrismEditor,
    StatusFlash,
  },

  validations() {
    return {
      settings: {
        appTitle: {},
        appSubtitle: {},
        metadataMetrics: {
          $each: {
            metricUri: { required, url },
            resourceUri: { required, url },
          },
        },
        ping: {
          enabled: { required },
          endpoints: {
            $each: {
              endpoint: { required, url },
            },
          },
        },
        forms: {
          autocomplete: {
            searchNamespace: { required },
            sources: {
              $each: {
                rdfType: { required },
                sparqlEndpoint: { required },
                sparqlQuery: { required },
              },
            },
          },
        },
        search: {
          filters: {
            $each: {
              type: { required },
              label: { required },
              predicate: { required, url },
              queryFromRecords: {},
              values: {
                $each: {
                  label: {},
                  value: { required },
                },
              },
            },
          },
        },
      },
    }
  },

  data() {
    return {
      status: new Status(),
      submitStatus: new Status(),
      settings: null,
      settingsData: null,
    }
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      try {
        this.status.setPending()
        const response = await api.settings.get()
        this.settingsData = response.data
        this.settings = this.requestDataToFormData(response.data)
        this.status.setDone()
      } catch (error) {
        this.status.setError('Unable to get settings')
      }
    },

    addMetadataMetric() {
      this.settings.metadataMetrics.push({
        metricUri: null,
        resourceUri: null,
      })
    },

    removeMetadataMetric(index) {
      this.settings.metadataMetrics.splice(index, 1)
    },

    addPingEndpoint() {
      this.settings.ping.endpoints.push({
        endpoint: null,
      })
    },

    removePingEndpoint(index) {
      this.settings.ping.endpoints.splice(index, 1)
    },

    addSource() {
      this.settings.forms.autocomplete.sources.push({
        rdfType: '',
        sparqlEndpoint: '',
        sparqlQuery: '',
      })
    },

    removeSource(index) {
      this.settings.forms.autocomplete.sources.splice(index, 1)
    },

    addFilter() {
      this.settings.search.filters.push({
        type: 'IRI',
        label: '',
        predicate: '',
        values: [],
        queryFromRecords: false,
      })
    },

    removeFilter(index) {
      this.settings.search.filters.splice(index, 1)
    },

    addFilterValue(filterIndex) {
      this.settings.search.filters[filterIndex].values.push({ value: null, label: null })
    },

    removeFilterValue(filterIndex, valueIndex) {
      this.settings.search.filters[filterIndex].values.splice(valueIndex, 1)
    },

    endpointsFromConfigVisible() {
      return this.settings.ping.endpointsFromConfig
        && this.settings.ping.endpointsFromConfig.length > 0
    },

    async submitSettings() {
      this.$v.settings.$touch()

      if (!this.$v.settings.$invalid) {
        try {
          this.submitStatus.setPending()
          const response = await api.settings.put(this.formDataToRequestData(this.settings))
          this.settings = this.requestDataToFormData(response.data)
          this.submitStatus.setDone('Settings were successfully updated!')
        } catch (error) {
          this.submitStatus.setError('Settings could not be updated.')
        } finally {
          window.scrollTo(0, 0)
        }
      }
    },

    formDataToRequestData(formData) {
      return {
        appTitle: formData.appTitle,
        appSubtitle: formData.appSubtitle,
        metadataMetrics: formData.metadataMetrics,
        ping: {
          enabled: formData.ping.enabled,
          endpoints: formData.ping.endpoints.map((e) => e.endpoint),
        },
        forms: formData.forms,
        search: formData.search,
      }
    },

    requestDataToFormData(requestData) {
      const formData = { ...requestData }
      formData.ping.endpoints = formData.ping.endpoints.map((endpoint) => ({ endpoint }))
      return formData
    },
  },
}
