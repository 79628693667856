






























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Page extends Vue {
  @Prop({ type: String, default: null })
  readonly title?: string

  @Prop({ type: Boolean, default: false })
  readonly contentOnly: boolean

  @Prop({ type: Boolean, default: false })
  readonly small: boolean
}
